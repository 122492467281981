<script setup lang="ts">
import DropdownItem from '@/Components/UI/DropdownItem.vue'
import { Link, router } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import type { Category } from '@/Types/Models/Category'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, type PropType, ref } from 'vue'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const props = defineProps({
    item: Object as PropType<NavItem>
})

const dropdownClicked = ref(false)

async function hideDropdown () {
    dropdownClicked.value = true
    setTimeout(() => {
        dropdownClicked.value = false
    }, 200)
}

const notEmpty = (value: any) => {
    return value !== undefined && value !== null && value !== ''
}

const active = ref(false)

function markActive () {
    active.value = useCurrentUrl() === props.item.url

    if (props.item.children?.length) {
        props.item.children.forEach((child: NavItem | Category) => {
            if (useCurrentUrl() === child.url) {
                active.value = true
            }
        })
    }
}

const show = computed(() => {
    let canShow = (notEmpty(props.item.if) ? props.item.if : true)

    if (typeof props.item.permission !== 'undefined' && canShow) {
        canShow = props.item.permission === true
    }

    return canShow
})

onMounted(() => {
    router.on('navigate', () => {
        markActive()
    })
    markActive()
})
</script>

<template>
    <component
        :is="item.url ? Link : 'button'"
        v-if="show"
        :class="[dropdownClicked ? '' : 'group', active ? 'text-primary dark:text-primary-lighter' : 'text-slate-900 dark:text-white',
        ]"
        :href="item.url"
        class="relative flex h-full items-center border border-transparent px-2 text-sm transition-all lg:px-2">
        <div
            :class="active ? 'opacity-100' : 'opacity-0'"
            class="absolute top-1/2 left-1/2 -z-10 h-8 -translate-x-1/2 -translate-y-1/2 rounded-lg transition-all w-[110%] bg-primary/5 group-hover:opacity-100 dark:bg-primary/20" />
        <component
            :is="item.icon"
            v-if="item.icon && ['object', 'function'].includes(typeof item.icon)"
            class="mr-2 w-5" />
        {{ item.title }}
        <ChevronDownIcon
            v-if="item.children"
            class="ml-1 w-4" />
        <div
            v-if="item.children"
            class="pointer-events-none absolute top-full left-0 -ml-px w-72 -translate-y-2 pt-2 opacity-0 transition-all group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100 group-focus:translate-y-0 group-focus:opacity-100 dark:border-slate-700">
            <ul class="rounded-lg border border-zinc-200 bg-white p-1 shadow-sm">
                <li
                    v-for="child in item.children"
                    :key="child.title"
                    class="flex w-full">
                    <Link
                        :href="child.url"
                        class="flex w-full items-center gap-3 rounded-md px-3 py-2 hover:bg-zinc-100"
                        @click="hideDropdown">
                        <component
                            :is="child.icon"
                            v-if="child.icon && typeof child.icon !== 'string'"
                            class="size-5" />
                        <img
                            v-if="child.icon && typeof child.icon === 'string'"
                            :alt="child.title"
                            class="size-5"
                            :src="child.icon">
                        {{ child.title }}
                    </Link>
                    <!--                    <DropdownItem-->
                    <!--                        :key="child.title"-->
                    <!--                        class="rounded-md hover:bg-primary !hover:text-white dark:hover:bg-primary"-->
                    <!--                        :item="child"-->
                    <!--                        @click="hideDropdown" />-->
                </li>
            </ul>
        </div>
    </component>
</template>
