<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission.js'
import PostForm from '@/Components/Post/PostForm.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import PostSingle from '@/Components/Post/PostSingle.vue'
import type { User } from '@/Types/Models/User'
import type { Post } from '@/Types/Models/Post'
import { computed, type PropType, ref } from 'vue'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser'
import { useHasPagination } from '@/Composables/useHasPagination.ts'

const props = defineProps({
    posts: Object as PropType<PaginatedItems<Post>>,
    user: Object as PropType<User>,
    paginated: Boolean,
    includeForm: {
        type: Boolean,
        default: true
    }
})

const postsData = ref(props.paginated ? props.posts.data : props.posts)

const emit = defineEmits(['reload'])

const showForm = computed(() => {
    return props.includeForm && useIsAuthedUser(props.user) && usePermissions(UserPermission.CREATE_POST)
})

function reload () {
    emit('reload')
}
</script>

<template>
    <div>
        <PostForm
            v-if="showForm"
            @reload="reload"
        />
        <div
            :class="showForm ? 'mt-12' : 'mt-2'"
            class="flex flex-col gap-2.5">
            <PostSingle
                v-for="post in postsData"
                :key="post.id"
                :post="post"
                @deleted="emit('reload')"
                @reload="emit('reload')" />

            <Pagination
                v-if="useHasPagination<Post>(posts)"
                :show-count="true"
                :data="posts" />
        </div>
    </div>
</template>
