<script setup>
import * as UserPermission from '@/Enums/UserPermission'
import Icon from '@/Components/Icon'
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

const props = defineProps({
    user: Object,
    confirmUnfollow: {
        type: Boolean,
        default: true
    },
    showLoginModal: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    },
    xSmall: {
        type: Boolean,
        default: false
    },
    iconOnly: {
        type: Boolean,
        default: false
    },
    padding: {
        type: String,
        default: 'px-4 py-2'
    },
    following: Boolean
})

const emit = defineEmits(['followed', 'unfollowed', 'updated'])

const isFollowing = ref(props.user.isFollowing)
const displayConfirmUnfollow = ref(false)

useEmitter.on('followersUpdated', function (e) {
    if (e.user === props.user.id) {
        isFollowing.value = e.data.followed
    }
})

function follow () {
    isFollowing.value = true
    usePost({
        url: useRoute('api.users.follows.store'),
        data: {
            user_id: usePageProps().auth.user.id,
            following_id: props.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            useToast(response.message, 'success')
            emit('followed')
            emit('updated', {
                user: props.user.id,
                data: response
            })
            useEmitter.emit('followed', {
                user: props.user.id,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                user: props.user.id,
                data: response
            })

            useAnalyticsEvent('Follow user')
        }
    })
}

function unfollow () {
    isFollowing.value = false
    displayConfirmUnfollow.value = false
    usePost({
        url: useRoute('api.users.follows.destroy'),
        method: 'DELETE',
        data: {
            user_id: usePageProps().auth.user.id,
            following_id: props.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            useToast(response.message, 'success')
            emit('unfollowed')
            emit('updated', {
                user: props.user.id,
                data: response
            })
            useEmitter.emit('followed', {
                user: props.user.id,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                user: props.user.id,
                data: response
            })
            useAnalyticsEvent('Unfollow user')
        }
    })
}

function handleClick () {
    if (!useIsAuthed() && props.showLoginModal) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to follow users',
            registerTitle: 'Register to follow users',
            defaultForm: 'login'
        })
    } else {
        if (isFollowing.value) {
            if (props.confirmUnfollow) {
                displayConfirmUnfollow.value = true
            } else {
                unfollow()
            }
        } else {
            follow()
        }
    }
}
</script>

<template>
    <div>
        <div
            v-if="!useIsAuthed() || (useIsAuthed() && usePermissions(UserPermission.FOLLOW_USER))"
            class="w-full">
            <Button
                :key="`following-${isFollowing}`"
                v-tooltip="small ? (isFollowing ? `Unfollow` : `Follow`) : ``"
                :variant="isFollowing ? 'white' : 'primary'"
                :small="small"
                :x-small="xSmall"
                :icon-only="iconOnly"
                :icon="isFollowing ? Icon.UserMinus : Icon.UserPlus"
                @click="handleClick">
                {{ isFollowing ? 'Following' : 'Follow' }}
            </Button>
        </div>

        <Modal
            :show="displayConfirmUnfollow"
            max-width="md"
            @close="displayConfirmUnfollow = false">
            <template #title>
                Unfollow {{ user.name }}
            </template>

            <template #content>
                Are you sure you want to unfollow {{ user.name }}
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="displayConfirmUnfollow = false">
                        Cancel
                    </Button>

                    <Button
                        variant="secondary"
                        @click="unfollow()">
                        Yes, unfollow
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
