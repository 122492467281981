<script setup lang="ts">
import draggable from 'vuedraggable'
import Modal from '@/Components/UI/Modal.vue'
import Badge from '@/Components/UI/Badge.vue'
import Button from '@/Components/UI/Button.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import ModelMapReverse from '@/Configs/ModelMapReverse.ts'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ArticleRatingsConfig from '@/Configs/ArticleRatings.ts'
import ConfirmModal from '@/Components/Global/ConfirmModal.vue'
import RatingRenderer from '@/Components/Ratings/RatingRenderer.vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import type { ArticleRating } from '@/Types/ArticleRating'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { onMounted, type PropType, ref, shallowRef } from 'vue'
import { Bars2Icon, ChevronUpIcon, ChevronDownIcon, PencilIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    article: Object as PropType<Article>,
    ratings: Array as PropType<ArticleRating<any>[]>
})

const showCreateRatingModal = ref(false)
const showEditRatingModal = ref(false)
const editRating = ref(null)
const displayRatingForm = ref(false)
const displayedRatingForm = shallowRef(null)

const ratingTypes = ArticleRatingsConfig

const emit = defineEmits(['update'])

function showRatingForm (ratingType: { title: string, form: any }) {
    displayRatingForm.value = true
    displayedRatingForm.value = ratingType.form
}

function openEditModal (rating: ArticleRating<any>) {
    editRating.value = rating
    showEditRatingModal.value = true
}

function closeEditModal () {
    editRating.value = null
    showEditRatingModal.value = false
}

function closeCreateModal () {
    showCreateRatingModal.value = false
    displayRatingForm.value = false
}

function deleteRating (rating: ArticleRating<any>) {
    useMakeRequest(useRoute('api.ratings.delete', rating.uuid), 'DELETE', {})
        .then(results => {
            if (results.success === true) {
                emit('update')
                order.value = order.value.filter(uuid => uuid !== rating.uuid)
                // useToast('Rating deleted')
            }
        })
}

function moveUp (uuid: string) {
    const index = order.value.indexOf(uuid)
    if (index > 0) {
        const newIndex = index - 1
        order.value.splice(newIndex, 0, order.value.splice(index, 1)[0])
    }
}

function moveDown (uuid: string) {
    const index = order.value.indexOf(uuid)
    if (index < order.value.length - 1) {
        const newIndex = index + 1
        order.value.splice(newIndex, 0, order.value.splice(index, 1)[0])
    }
}

function created () {
    closeCreateModal()
    emit('update')
}

function updated () {
    closeEditModal()
    emit('update')
}

function openCreateModal () {
    showCreateRatingModal.value = true
    displayRatingForm.value = true
    displayedRatingForm.value = ratingTypes[0].form
}

function canMoveUp (element: string) {
    return order.value.indexOf(element) > 0
}

function canMoveDown (element: string) {
    return order.value.indexOf(element) < order.value.length - 1
}

const order = defineModel('order', {
    type: Array
})

const error = defineModel('error', {
    type: String
})

function getRatingByUuid (uuid: string) {
    return props.ratings.find(rating => rating.uuid === uuid)
}

onMounted(() => {
    if (!order.value.length || order.value.length !== props.ratings.length) {
        order.value = props.ratings.map(rating => rating.uuid)
    }
})
</script>

<template>
    <div class="relative">
        <div
            :class="ratings.length ? 'border-b border-primary/10 dark:border-white/10' : ''"
            class="mb-8 flex items-center justify-between pb-2">
            <SectionTitle font-size="text-2xl md:text-3xl">
                <div class="flex items-center gap-2">
                    Ratings
                    <Badge
                        v-if="ratings.length"
                        class="mt-1"
                        :text="ratings.length"
                        colour="primary" />
                </div>
            </SectionTitle>
            <Button
                type="button"
                @click="openCreateModal">
                Add Rating
            </Button>
        </div>
        <div
            v-if="error"
            class="-mt-6 mb-4">
            <ErrorText
                :error="`Something went wrong, please refresh or <a href='${useRoute('contact')}' target='_blank' class='underline'>contact us</a>`" />
        </div>
        <draggable
            v-model="order"
            group="uuids"
            class="flex flex-col gap-6"
            tag="ul"
            handle=".handle"
            ghost-class="opacity-50"
            item-key="id">
            <template #item="{element}">
                <li class="w-full transition-all">
                    <div class="mb-1 flex w-full justify-between">
                        <div class="flex items-center gap-4">
                            <div
                                v-tooltip="`Drag and drop reorder`"
                                class="pl-2 text-slate-900/50 transition-all handle hover:text-slate-900">
                                <Bars2Icon class="w-5 cursor-grab" />
                            </div>
                            <button
                                v-tooltip="`Move up`"
                                type="button"
                                class="text-slate-900/50 transition-all hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                                :disabled="!canMoveUp(element)"
                                @click="moveUp(element)">
                                <ChevronUpIcon class="w-5" />
                            </button>
                            <button
                                v-tooltip="`Move down`"
                                type="button"
                                class="text-slate-900/50 transition-all hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                                :disabled="!canMoveDown(element)"
                                @click="moveDown(element)">
                                <ChevronDownIcon class="w-5" />
                            </button>
                        </div>
                        <div class="flex items-center gap-4 pr-2">
                            <button
                                type="button"
                                class="flex items-center gap-1 text-sm text-slate-900/50 transition-all hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50"
                                @click="openEditModal(getRatingByUuid(element))">
                                <PencilIcon class="w-4" />
                                Edit
                            </button>

                            <ConfirmModal
                                title="Delete Rating"
                                content="Are you sure you want to delete this rating?"
                                @confirmed="deleteRating(getRatingByUuid(element))">
                                <button
                                    type="button"
                                    class="flex items-center gap-1 text-sm text-slate-900/50 transition-all hover:text-slate-900 disabled:pointer-events-none disabled:opacity-50">
                                    <XMarkIcon class="w-5" />
                                </button>
                            </ConfirmModal>
                        </div>
                    </div>
                    <div class="flex w-full flex-col">
                        <RatingRenderer
                            :key="order.join('-')"
                            :rating="getRatingByUuid(element)" />
                    </div>
                </li>
            </template>
        </draggable>

        <Modal
            max-width="4xl"
            :show="showEditRatingModal"
            @close="showEditRatingModal = false">
            <template #title>
                Edit Rating
            </template>
            <template #content>
                <component
                    :is="ratingTypes.find(rating => rating.key === editRating?.type)?.form"
                    v-if="editRating"
                    :resettable="false"
                    :cancelable="true"
                    :rating="editRating"
                    :rateable-type="ModelMapReverse['Article']"
                    :rateable="article"
                    @submit="updated"
                    @cancel-edit="closeEditModal">
                    <template #actions>
                        <Button
                            variant="primary-light"
                            @click="closeEditModal">
                            Cancel
                        </Button>
                    </template>
                </component>
            </template>
        </Modal>

        <Modal
            max-width="4xl"
            :show="showCreateRatingModal"
            @close="showCreateRatingModal = false">
            <template #title>
                Add a Rating
            </template>
            <template #content>
                <div class="relative mt-4 mb-6">
                    <ul class="flex flex-wrap gap-2">
                        <li
                            v-for="ratingType in ratingTypes"
                            :key="ratingType.title">
                            <button
                                :class="displayedRatingForm === ratingType.form ? 'bg-primary text-white' : 'bg-primary/5 hover:bg-primary/10 text-primary'"
                                class="rounded-full px-4 py-1 text-sm"
                                @click="showRatingForm(ratingType)">
                                {{ ratingType.title }}
                            </button>
                        </li>
                    </ul>
                </div>

                <div v-if="displayRatingForm">
                    <component
                        :is="displayedRatingForm"
                        :rateable-type="ModelMapReverse['Article']"
                        :rateable="article"
                        :cancelable="true"
                        :resettable="false"
                        :cancel-edit="closeCreateModal"
                        @submit="created">
                        <template #actions>
                            <Button
                                variant="primary-light"
                                @click="closeCreateModal">
                                Cancel
                            </Button>
                        </template>
                    </component>
                </div>
            </template>
        </Modal>
    </div>
</template>
