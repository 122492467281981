<script setup>

import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const props = defineProps({
    email: {
        type: String,
        required: true
    },
    token: {
        type: String,
        required: true
    }
})

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: ''
})

const submit = () => {
    form.post(useRoute('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation')
    })
}

defineOptions({ layout: MinimalAuthLayout })
</script>

<template>
    <div>
        <form @submit.prevent="submit">
            <SectionTitle font-size="md:text-3xl">
                Reset password
            </SectionTitle>
            <div class="mt-4">
                <InputText
                    v-model="form.email"
                    :error="form.errors.email"
                    autocomplete="username"
                    input-type="email"
                    label="Your email address"
                />
            </div>

            <div class="mt-4 flex flex-col space-y-4">
                <InputText
                    v-model="form.password"
                    input-type="password"
                    :error="form.errors.password"
                    autocomplete="new-password"
                    label="New password" />
                <InputText
                    v-model="form.password_confirmation"
                    input-type="password"
                    :error="form.errors.password_confirmation"
                    autocomplete="new-password"
                    label="Confirm password" />
            </div>

            <div class="mt-4">
                <Button
                    :disabled="form.processing"
                    class="w-full">
                    Reset Password
                </Button>
            </div>
        </form>
    </div>
</template>
