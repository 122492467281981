<script setup lang="ts">
import draggable from 'vuedraggable'
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useGenerateId } from '@/Composables/useGenerateId.ts'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { Bars2Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Bars3CenterLeftIcon, PlusIcon } from '@heroicons/vue/24/solid'

interface FormValue {
    id?: string | number;
    title?: string;

    [key: string]: any;
}

interface FormMeta {
    values: FormValue[];
}

interface Form {
    rating?: string;
    title: string;
    description: string;
    meta: FormMeta;
    isDirty: boolean;
    reset: () => void;
    post: (url: string, options?: object) => void;
    submit: (method: string, url: string, options?: object) => void;
}

const props = defineProps({
    canHaveMultipleValues: {
        type: Boolean,
        default: true
    },
    forceColumn: {
        type: Boolean,
        default: false
    },
    hasIndividualTitles: {
        type: Boolean,
        default: true
    },
    inline: {
        type: Boolean,
        default: false
    },
    defaultValue: {
        type: Object,
        required: true
    },
    resettable: {
        type: Boolean,
        default: true
    },
    rating: {
        type: [Object, null]
    }
})

const emit = defineEmits(['addValue', 'hideTitle', 'submit', 'reset', 'cancel-edit'])

const maxValues = 5

const form = defineModel<Form>('form', {
    type: Object,
    default: () => ({
        rating: null,
        title: '',
        description: '',
        meta: { values: [] },
        isDirty: false,
        reset: () => {
        },
        post: () => {
        },
        submit: () => {
        }
    })
})

const errors = ref<string|string[]>([])

const titleTextarea = ref<HTMLTextAreaElement | null>(null)
const descriptionTextarea = ref<HTMLTextAreaElement | null>(null)
const displayTitle = ref(true)

const valuesCount = computed(() => form.value.meta.values.length)
const canAddValue = computed(() => valuesCount.value < maxValues)
const hasMultipleValues = computed(() => valuesCount.value > 1)
const canRemoveValue = computed(() => valuesCount.value > 1)
const canMoveValues = computed(() => valuesCount.value > 1)

function autoresize (element: HTMLTextAreaElement | any) {
    if (!element) return
    element.style.height = '38px'
    element.style.height = `${element.scrollHeight}px`
}

function addValue () {
    if (!canAddValue.value) return

    const id = useGenerateId().toString()
    const newValue = { id, ...props.defaultValue }
    form.value.meta.values.push(newValue as FormValue)
}

function toggleDisplayTitle () {
    displayTitle.value = !displayTitle.value
    if (displayTitle.value) {
        nextTick(() => {
            autoresize(titleTextarea.value)
            autoresize(descriptionTextarea.value)
        })
    } else {
        emit('hideTitle')
    }
}

function submit () {
    const formToSubmit = { ...form.value, meta: { ...form.value.meta } }
    formToSubmit.meta.values = formToSubmit.meta.values.map(({ id, ...rest }) => rest)

    const postRoute = isEditing.value
        ? useRoute('api.ratings.update', { rating: props.rating.uuid })
        : useRoute('api.ratings.store')

    formToSubmit.submit(isEditing.value ? 'put' : 'post', postRoute, {
        preserveScroll: true,
        onStart: () => {
            errors.value = []
        },
        onFinish: () => {
            if (usePageProps().errors.rating) {
                errors.value = usePageProps().errors.rating
            }
        },
        onSuccess: () => {
            emit('submit')
            formToSubmit.reset()
            reset()
        }
    })
}

const uniqueId = useGenerateId().toString()

function resizeTitleInputs () {
    const titleInputs = document.querySelectorAll(`.title-${uniqueId}`)

    titleInputs.forEach((input: HTMLTextAreaElement) => {
        autoresize(input)
    })
}

function reset () {
    if (isEditing.value) {
        prefillForm()
    } else {
        form.value.reset()
    }
    displayTitle.value = true
    // form.value.meta.values = [props.defaultValue]
    errors.value = []
    nextTick(() => {
        resizeTitleInputs()
        autoresize(titleTextarea.value)
        autoresize(descriptionTextarea.value)
    })
    emit('reset')
}

function removeValue (id: string | number) {
    if (!canRemoveValue.value) return
    const index = form.value.meta.values.findIndex(value => value.id === id)
    if (index !== -1) {
        form.value.meta.values.splice(index, 1)
    }
}

const submittable = computed(() => {
    if (hasMultipleValues.value && !displayTitle.value) {
        return (props.hasIndividualTitles && form.value.meta.values.every(value => value.title?.length > 0))
    }

    if (hasMultipleValues.value && displayTitle.value) {
        return form.value.title?.length > 0 ||
            (props.hasIndividualTitles &&
                form.value.meta.values.every(value => value.title?.length > 0)
            )
    }
    return form.value.title?.length > 0 ||
        (props.hasIndividualTitles &&
            form.value.meta.values.every(value => value.title?.length > 0)
        )
})

watch(() => form.value.description, () => autoresize(descriptionTextarea.value))
watch(() => form.value.title, () => autoresize(titleTextarea.value))
watch(() => form.value.meta, () => resizeTitleInputs(), { deep: true })

const isEditing = computed(() => !!props.rating)

function prefillForm () {
    form.value.rating = props.rating.uuid
    form.value.title = props.rating.title
    form.value.description = props.rating.description
    form.value.meta.values = props.rating.meta.values
}

onMounted(() => {
    autoresize(descriptionTextarea.value)
    autoresize(titleTextarea.value)
    resizeTitleInputs()
    if (isEditing.value) {
        prefillForm()
    }
    errors.value = usePageProps().errors.rating
})

window.addEventListener('resize', () => {
    autoresize(titleTextarea.value)
    autoresize(descriptionTextarea.value)
    resizeTitleInputs()
})
</script>

<template>
    <div>
        <div class="relative pb-6">
            <div
                class="relative flex flex-col items-center"
                :class="(hasMultipleValues || forceColumn) ? 'md:flex-col' : 'md:flex-row gap-4'">
                <div
                    v-if="displayTitle"
                    class="flex w-full shrink-0 flex-col gap-0"
                    :class="(hasMultipleValues || forceColumn) ? 'w-full md:pr-40' : 'md:w-8/12'">
                    <textarea
                        ref="titleTextarea"
                        v-model="form.title"
                        maxlength="155"
                        class="max-h-32 resize-none overflow-hidden text-xl font-semibold outline-none min-h-[39px] letter-spacing-tight font-heading sm:text-2xl"
                        placeholder="Title"
                        @input="autoresize(titleTextarea)" />
                    <textarea
                        ref="descriptionTextarea"
                        v-model="form.description"
                        maxlength="155"
                        class="max-h-16 resize-none overflow-hidden text-sm text-slate-900/75 outline-none min-h-[38px] -mt-0.5 dark:text-white/75"
                        placeholder="Description"
                        @input="autoresize(descriptionTextarea)" />
                </div>
                <div
                    class="flex w-full"
                    :class="[
                        canHaveMultipleValues ? 'min-h-[120px]' : '',
                        (hasMultipleValues && !displayTitle) ? 'mt-4' : '',
                        forceColumn && displayTitle && hasMultipleValues ? '-mt-4' : ''
                    ]">
                    <draggable
                        v-model="form.meta.values"
                        :disabled="!canMoveValues"
                        handle=".handle"
                        class="flex w-full flex-col items-stretch text-slate-900 divide-y divide-background"
                        :class="[
                            ((!hasMultipleValues && displayTitle) || forceColumn) ? 'justify-end' : 'justify-center',
                            forceColumn ? 'mt-4' : 'md:flex-row md:divide-x sm:divide-y-0'
                        ]"
                        ghost-class="rating-ghost"
                        item-key="id">
                        <template #item="{ element }">
                            <li
                                :class="[
                                    hasMultipleValues && !forceColumn ? 'pt-10 pb-0' : '',
                                    hasMultipleValues && forceColumn ? 'pt-16 pb-0' : '',
                                    !hasMultipleValues ? 'md:py-0' : ''
                                ]"
                                class="relative flex w-full flex-col justify-center">
                                <div
                                    :class="forceColumn ? 'right-0 left-0' : 'md:top-0 right-4 left-4 '"
                                    class="absolute top-4 z-20 flex">
                                    <div
                                        v-if="canMoveValues"
                                        class="cursor-move opacity-25 transition-all handle hover:opacity-100">
                                        <Bars2Icon class="w-5" />
                                    </div>
                                    <button
                                        v-if="canRemoveValue"
                                        class="ml-auto opacity-25 transition-all hover:opacity-100"
                                        @click="removeValue(element.id)">
                                        <XMarkIcon class="w-5" />
                                    </button>
                                </div>

                                <div class="relative">
                                    <slot
                                        name="element"
                                        :element="element" />
                                </div>

                                <div
                                    v-if="hasIndividualTitles"
                                    :class="forceColumn ? 'mt-0' : 'mt-4'"
                                    class="relative mx-4 block break-words text-center text-lg font-bold leading-5 font-heading">
                                    <textarea
                                        :ref="`titleInput${element.id}`"
                                        v-model="element.title"
                                        type="text"
                                        :class="'title-' + uniqueId"
                                        class="mx-auto w-full resize-none overflow-hidden bg-transparent text-center outline-none min-h-[38px]"
                                        placeholder="Title" />
                                </div>
                                <div
                                    v-if="errors"
                                    class="flex w-full flex-col justify-center text-center">
                                    <ErrorText
                                        v-for="error in errors"
                                        :key="element.id + '-' + error"
                                        class="mt-2"
                                        :error="error" />
                                </div>
                            </li>
                        </template>
                    </draggable>
                </div>
            </div>
            <div class="mt-4 flex items-center justify-between border-t pt-4 border-background -mb-2.5 -mx-2.5">
                <div class="flex gap-4">
                    <Button
                        v-if="canHaveMultipleValues"
                        v-tooltip="displayTitle ? 'Hide title' : 'Show title'"
                        variant="primary-light"
                        class="rounded-full"
                        padding="p-1"
                        @click="toggleDisplayTitle">
                        <span class="w-5">
                            <Bars3CenterLeftIcon class="w-full" />
                        </span>
                    </Button>
                    <Button
                        v-if="canHaveMultipleValues"
                        variant="primary-light"
                        class="rounded-full"
                        padding="p-1"
                        :disabled="!canAddValue"
                        @click="addValue">
                        <span class="w-5">
                            <PlusIcon class="w-full" />
                        </span>
                    </Button>
                </div>
                <div class="flex items-center gap-4">
                    <slot name="actions" />
                    <Button
                        v-if="resettable"
                        variant="primary-light"
                        :disabled="!form.isDirty"
                        @click="reset">
                        Reset
                    </Button>
                    <Button
                        variant="primary"
                        :disabled="!submittable"
                        @click="submit">
                        {{ isEditing ? "Update" : "Create" }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
