<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import { ChevronRightIcon, ChevronLeftIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/20/solid'

type Pagination = {
    links?: {
        first: string | null,
        prev: string | null,
        next: string | null,
        last: string | null,
        url: string | null,
        label: string,
        active: boolean
    }[],
    meta?: {
        current_page: number,
        links?: {
            url: string | null,
            label: string,
            active: boolean
        }[]
    }
}

const props = defineProps({
    data: Object as PropType<Pagination>,
    only: Array as PropType<string[]>,
    preserveScroll: Boolean,
    showCount: {
        type: Boolean,
        default: true
    },
    showEndLinks: {
        type: Boolean,
        default: true
    }
})

function getIcon (link: { label: string }): typeof ChevronRightIcon | typeof ChevronLeftIcon | null {
    if (link.label.includes('Next')) {
        return ChevronRightIcon
    }
    if (link.label.includes('Previous')) {
        return ChevronLeftIcon
    }
    return null
}

const currentPage = computed(() => props.data?.meta?.current_page ?? 1)
</script>

<template>
    <div
        v-if="data && data.meta?.links"
        class="flex py-4 text-center">
        <div class="mx-auto flex flex-wrap gap-0 button-group">
            <template
                v-for="(link, key) in data.meta.links"
                :key="key">
                <Button
                    v-if="link.url === null"
                    variant="white"
                    :icon="getIcon(link)"
                    :icon-only="!!getIcon(link)"
                    :disabled="true">
                    {{ link.label }}
                </Button>
                <Button
                    v-else
                    :key="`link-${key}-${currentPage}`"
                    :variant="link.active ? 'secondary' : 'white'"
                    :href="link.url"
                    :only="only"
                    preserve-state
                    :icon-only="!!getIcon(link)"
                    :icon="getIcon(link)"
                    :preserve-scroll="preserveScroll">
                    {{ link.label }}
                </Button>
            </template>
        </div>
    </div>
    <div v-else>
        <div class="mt-4 flex w-full items-center justify-center gap-2">
            <Button
                v-if="showEndLinks"
                variant="white"
                :disabled="!data?.links?.first"
                :href="data?.links?.first || '#'"
                :only="only"
                icon-only
                :icon="ChevronDoubleLeftIcon"
                :preserve-scroll="preserveScroll" />
            <Button
                variant="white"
                :disabled="!data?.links?.prev"
                :href="data?.links?.prev || '#'"
                :only="only"
                icon-only
                :icon="ChevronLeftIcon"
                :preserve-scroll="preserveScroll" />

            <SectionDescription v-if="showCount">
                {{ `Page ${data?.meta?.current_page}` }}
            </SectionDescription>

            <Button
                variant="white"
                :disabled="!data?.links?.next"
                :href="data?.links?.next || '#'"
                :only="only"
                :icon="ChevronRightIcon"
                icon-only
                :preserve-scroll="preserveScroll" />

            <Button
                v-if="showEndLinks"
                variant="white"
                :disabled="!data?.links?.last"
                :href="data?.links?.last || '#'"
                :only="only"
                :icon="ChevronDoubleRightIcon"
                icon-only
                :preserve-scroll="preserveScroll" />
        </div>
    </div>
</template>
