<script setup lang="ts">
import isEqual from 'lodash/isEqual'
import Card from '@/Components/UI/Card.vue'
import Spinner from '@/Components/UI/Spinner.vue'
import ViewGraph from '@/Components/Global/ViewGraph.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import ArticleInfo from '@/Components/Article/ArticleInfo.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import InputRangePicker from '@/Components/Input/InputRangePicker.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import ArticleCardHorizontal from '@/Components/Article/ArticleCardHorizontal.vue'
import { router } from '@inertiajs/vue3'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { ref, watch, onMounted, computed } from 'vue'
import type { Article } from '@/Types/Models/Article'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useFormatNumber } from '@/Composables/useFormatNumber.ts'
import { ChatBubbleOvalLeftIcon, EyeIcon, HeartIcon } from '@heroicons/vue/24/solid'

interface GraphData {
    [key: string]: any;
}

interface ViewsList {
    article: Article;
    visits: number;
}

interface ViewsSources {
    [source: string]: {
        percentage: number;
        count: number;
    };
}

type Range = 'today' | '7' | '28' | '60' | 'all' | 'custom'
type TabKeys = 'article_views' | 'profile_views' | 'comments' | 'likes' | 'followers'

interface CountType {
    article_views: number | null;
    profile_views: number | null;
    comments: number | null;
    likes: number | null;
    followers: number | null;
}

type Counts = {
    [K in keyof CountType]: CountType[K];
};

interface Props {
    article?: Article,
    selectedTab: TabKeys;
    selectedRange: Range;
    availableTabs: string[];
    selectedRangeDates: [string, string] | null;
    articleViewsTotal?: number;
    articleViewsGraph?: GraphData;
    lists?: { 'articleViewsList': ViewsList[], 'articleViewsSources': ViewsSources };
    profileViewsGraph?: GraphData;
    articleCommentsGraph?: GraphData;
    articleLikesGraph?: GraphData;
    followersGraph?: GraphData;
    counts?: Counts;
}

const props = defineProps<Props>()

const route = ref('dashboard.analytics')

interface Tab {
    key: TabKeys;
    name: string;
    number: number | null;
    current: boolean;
    data?: string[];
    hasGraph?: boolean;
    graph?: string;
}

const tabs = ref<Tab[]>([
    {
        key: 'article_views',
        name: 'Article views',
        number: null,
        current: false,
        hasGraph: true,
        graph: 'articleViewsGraph'
    },
    {
        key: 'profile_views',
        name: 'Profile views',
        number: null,
        current: false,
        hasGraph: true,
        graph: 'profileViewsGraph'
    },
    { key: 'comments', name: 'Comments', number: null, current: false, hasGraph: true, graph: 'articleCommentsGraph' },
    { key: 'likes', name: 'Likes', number: null, current: false, hasGraph: true, graph: 'articleLikesGraph' },
    { key: 'followers', name: 'Followers', number: null, current: false, hasGraph: true, graph: 'followersGraph' }
])

function setActiveTab (tab: Tab) {
    if (tab.current) return

    tabs.value.forEach((t) => (t.current = false))
    tab.current = true

    router.get(
        useRoute(route.value, {
            article: props.article?.uuid,
            tab: tab.key,
            range: props.selectedRange,
            start: props.selectedRange === 'custom' ? props.selectedRangeDates?.[0] : null,
            end: props.selectedRange === 'custom' ? props.selectedRangeDates?.[1] : null
        }),
        {},
        {
            preserveState: true,
            preserveScroll: true,
            onStart: () => {
                loadingGraph.value = true
            },
            onFinish: () => {
                const data = ['counts', 'lists']
                if (tab.hasGraph) data.push(tab.graph)

                router.reload({
                    only: data,
                    onFinish: () => {
                        tabs.value.forEach((t) => {
                            if (props.counts && props.counts[t.key] !== undefined) {
                                t.number = props.counts[t.key]
                            }
                        })
                        setTimeout(() => {
                            loadingGraph.value = false
                        }, 500)
                    }
                })
            }
        }
    )
}

const loadingGraph = ref(false)
const loadingCount = ref(false)
const rangeOption = ref(props.selectedRange)
const rangeDates = ref<[string | null, string | null] | null>(props.selectedRangeDates || [null, null])

const articleStats = computed(() => [
    {
        label: 'View',
        value: props.article.views,
        component: EyeIcon
    },
    {
        label: 'Like',
        value: props.article.likes_count,
        component: HeartIcon
    },
    {
        label: 'Comment',
        value: props.article.comments_count,
        component: ChatBubbleOvalLeftIcon
    }
])

watch(rangeOption, (newRangeOption) => {
    if (newRangeOption === 'custom') return

    router.get(useRoute(route.value, {
        article: props.article?.uuid,
        range: newRangeOption,
        tab: props.selectedTab
    }), {}, {
        preserveScroll: true,
        onStart: () => (loadingCount.value = true),
        onFinish: () => {
            loadingCount.value = false
        }
    })
})

const currentTab = computed(() => tabs.value.find((tab) => tab.current) ?? tabs.value[0])

watch(rangeDates, (newRangeDates) => {
    if (isEqual(newRangeDates, props.selectedRangeDates) || newRangeDates.length !== 2) return

    router.get(
        useRoute(route.value, {
            range: 'custom',
            article: props.article?.uuid,
            start: rangeDates.value?.[0],
            end: rangeDates.value?.[1],
            tab: props.selectedTab
        }),
        {},
        {
            preserveScroll: true,
            onStart: () => (loadingCount.value = true),
            onFinish: () => {
                loadingCount.value = false
            }
        }
    )
})

onMounted(() => {
    if (props.article) {
        route.value = 'dashboard.articles.analytics'
    }

    tabs.value = tabs.value.filter((tab) => props.availableTabs.includes(tab.key))
    const currentTab = tabs.value.find((tab) => tab.key === props.selectedTab) ?? tabs.value[0]

    if (currentTab) setActiveTab(currentTab)
})

defineOptions({
    layout: (h, page) => h(DashboardLayout, {}, () => [page]),
    inheritAttrs: false
})
</script>

<template>
    <div>
        <div class="relative flex w-full flex-col md:flex-row">
            <DashboardTitle
                class="w-full"
                :title="article ? 'Article analytics' : 'Overall analytics'"
                :description="usePageProps().meta?.description">
                <template #actions>
                    <InputRangePicker
                        v-model:selected-range="rangeOption"
                        v-model:range="rangeDates" />
                </template>
            </DashboardTitle>
        </div>
        <div class="mx-auto max-w-7xl">
            <Card
                v-if="article"
                class="mb-8 flex flex-col justify-between gap-8 md:mb-4 xl:flex-row">
                <div
                    class="flex w-full flex-col gap-4 md:flex-row">
                    <a
                        :href="article.permalink"
                        target="_blank"
                        class="w-full shrink-0 md:w-40">
                        <ArticleThumbnail
                            :actions="false"
                            :article="article" />
                    </a>
                    <div class="flex flex-col gap-0 pt-1">
                        <a
                            :href="article.permalink"
                            target="_blank"
                            class="text-xl transition-all font-heading text-pretty text-slate-950 hover:text-primary">
                            {{ article.title }}
                        </a>
                        <div>
                            <ArticleInfo
                                :article
                                class="flex items-center gap-1 pt-1 text-xs text-slate-900/75 dark:text-white/75 md:gap-1"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex w-full shrink-0 items-center justify-between gap-4 px-4 text-xs xl:w-48">
                    <div
                        v-for="stat in articleStats"
                        :key="stat.label"
                        v-tooltip="`${stat.value} ${usePlural(stat.label, stat.value) }`"
                        class="flex cursor-default flex-col text-center text-primary/50 gap-[2px] hover:text-primary dark:text-white/50 dark:hover:text-white">
                        <span>
                            <component
                                :is="stat.component"
                                class="w-6 text-slate-950/40" /></span>
                        <span
                            class="text-sm text-slate-950/50"
                            v-html="useFormatNumber(stat.value)" />
                    </div>
                </div>
            </Card>
            <div class="relative">
                <ul class="flex w-full flex-wrap gap-2 rounded-2xl md:border-primary/10 md:flex-nowrap md:gap-0 md:overflow-hidden md:rounded-b-none md:border md:border-b-0 md:bg-white">
                    <li
                        v-for="(tab, index) in tabs"
                        :key="tab.name"
                        :class="[
                            tab.current ? 'bg-primary border-primary' : 'bg-white'
                        ]"
                        class="w-auto shrink-0 rounded-full border md:w-full md:shrink md:rounded-none md:border-0 md:bg-transparent md:bg-white">
                        <button
                            :class="[
                                tab.current ? 'md:border-b-transparent cursor-default' : 'md:border-b-primary/10',
                                index === tabs.length - 1 ? '' : 'md:border-r border-r-primary/10',
                            ]"
                            class="relative flex w-full flex-row-reverse items-center justify-center gap-2 px-4 py-1 text-center group vor md:flex-col md:gap-1 md:border-b md:px-6 md:py-8"
                            @click="setActiveTab(tab)"
                        >
                            <span
                                :class="tab.current ? 'opacity-100 bg-primary' : 'bg-slate-900 opacity-0 group-hover:opacity-10'"
                                class="absolute top-0 left-0 hidden w-full h-1.5 bg-primary md:flex" />
                            <span
                                :class="tab.current ? 'text-white md:text-slate-900' : 'text-slate-900/50'"
                                class="text-sm">
                                {{ tab.name }}
                            </span>
                            <Spinner
                                v-if="loadingCount || tab.number === null"
                                :spinner="tab.current ? 'fill-white md:fill-primary' : 'fill-primary'"
                                :class="tab.current ? 'text-primary' : 'text-white'"
                                class="my-1 h-5 md:h-7 md:text-white" />
                            <span
                                v-else
                                v-tooltip="`${useFormatNumber(tab.number, false)}`"
                                :class="tab.current ? 'text-white md:text-primary' : 'text-primary'"
                                class="text-lg font-heading md:text-3xl">
                                {{ useFormatNumber(tab.number) }}
                            </span>
                        </button>
                    </li>
                </ul>
                <div
                    class="mt-4 rounded-2xl border bg-white p-4 border-primary/10 md:mt-0 md:rounded-t-none md:border-t-0">
                    <div class="relative h-80">
                        <div
                            :class="loadingGraph ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
                            class="absolute inset-0 z-20 flex h-full w-full items-center justify-center gap-2 bg-white/75">
                            <Spinner class="w-10 text-white" />
                        </div>
                        <ViewGraph
                            v-if="currentTab.hasGraph && props[currentTab.graph]"
                            :key="Object.keys(props[currentTab.graph]).length"
                            :show-labels="true"
                            :view-data="props[currentTab.graph]"
                            class="h-full"
                        >
                            <template #loading>
                                <div
                                    class="absolute inset-0 z-20 flex h-full w-full items-center justify-center gap-2 bg-white/75">
                                    <Spinner class="w-10 text-white" />
                                </div>
                            </template>
                        </ViewGraph>
                    </div>
                </div>
            </div>
            <div class="my-4 flex w-full flex-col gap-4 lg:flex-row">
                <div
                    v-if="lists?.articleViewsList && lists?.articleViewsList.length"
                    class="flex w-full flex-col gap-4 lg:w-2/3 xl:w-1/2">
                    <Card>
                        <SectionTitle font-size="text-xl">
                            Article views
                        </SectionTitle>
                        <ul class="mt-4 flex flex-col gap-8">
                            <li
                                v-for="item in lists.articleViewsList"
                                :key="item.article.uuid"
                                class="flex flex-col-reverse items-start justify-between gap-2 md:flex-row md:items-center md:gap-4">
                                <div class="w-full md:w-10/12">
                                    <ArticleCardHorizontal
                                        :actions="false"
                                        :article="item.article" />
                                </div>
                                <div class="flex w-auto cursor-default flex-col gap-0 md:w-2/12 md:items-center">
                                    <div
                                        v-tooltip="`${useFormatNumber(item.visits, false)}`"
                                        class="text-center text-2xl font-heading">
                                        {{ useFormatNumber(item.visits) }}
                                    </div>
                                    <div class="-mt-1 text-sm text-slate-950/50">
                                        {{ usePlural("view", item.visits) }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Card>

                    <!--                    <Card-->
                    <!--                        v-if="currentTab.data?.includes('articleViewsList')"-->
                    <!--                        class="overflow-scroll max-h-100">-->
                    <!--                        <SectionTitle font-size="text-xl">-->
                    <!--                            Article comments-->
                    <!--                        </SectionTitle>-->
                    <!--                        <ul-->
                    <!--                            v-for="(item, index) in articleCommentsList"-->
                    <!--                            :key="item.article.uuid"-->
                    <!--                            :class="index !== 0 ? 'border-t pt-8 border-black' : ''"-->
                    <!--                            class="mt-4 flex flex-col gap-8">-->
                    <!--                            <li>-->
                    <!--                                <ArticleCardHorizontal-->
                    <!--                                    :actions="false"-->
                    <!--                                    :article="item.article" />-->
                    <!--                                <ul class="mt-4 flex flex-col gap-4">-->
                    <!--                                    <li-->
                    <!--                                        v-for="comment in item.comments"-->
                    <!--                                        :key="comment.id"-->
                    <!--                                        class="flex flex-col-reverse items-start justify-between gap-2 md:flex-row md:items-center md:gap-0">-->
                    <!--                                        <div class="w-full md:w-9/12">-->
                    <!--                                            <CommentSingle-->
                    <!--                                                :actions="false"-->
                    <!--                                                :comment="comment" />-->
                    <!--                                        </div>-->
                    <!--                                    </li>-->
                    <!--                                </ul>-->
                    <!--                            </li>-->
                    <!--                        </ul>-->
                    <!--                    </Card>-->
                </div>
                <div
                    v-if="lists?.articleViewsSources && Object.keys(lists.articleViewsSources).length"
                    class="w-full lg:w-1/3 xl:w-1/2">
                    <Card>
                        <SectionTitle font-size="text-xl">
                            Sources
                        </SectionTitle>
                        <ul class="mt-2 flex flex-col gap-2.5">
                            <li
                                v-for="(data, source) in lists.articleViewsSources"
                                :key="source"
                                v-tooltip="`${data.percentage}%`"
                                class="flex flex-col gap-0 rounded-md group hover:bg-slate-900/5">
                                <div class="flex justify-between">
                                    <div class="relative flex w-full flex-col gap-1 text-sm text-slate-950 px-2.5 py-1.5">
                                        {{ source }}
                                        <div
                                            class="absolute top-0 left-0 h-full rounded bg-primary/10"
                                            :style="`width:${data.percentage}%`" />
                                    </div>
                                    <div class="flex w-12 shrink-0 items-center justify-end pr-4 text-sm font-semibold">
                                        {{ useFormatNumber(data.count) }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>
