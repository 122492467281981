<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import Spinner from '@/Components/UI/Spinner.vue'
import patternImage from '~/app/patterns/logo.svg'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ModalBackdrop from '@/Components/UI/ModalBackdrop.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import { useDebounceFn } from '@vueuse/core'
import type { Game } from '@/Types/Models/Game'
import { useRoute } from '@/Composables/useRoute.ts'
import { computed, onMounted, ref, watch } from 'vue'
import { ChevronRightIcon } from '@heroicons/vue/24/outline'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/vue/20/solid'
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue'

const props = defineProps({
    modelValue: {
        type: [Number, null],
        required: true
    },
    white: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    label: String
})

const emit = defineEmits(['update:modelValue'])
const loading = ref(false)
const selectedGame = ref<number | null>(props.modelValue)
const games = ref<Game[]>([])
const recent = ref<Game[]>([])
const open = ref(false)
const query = ref('')
const gameData = ref<Game>(null)

// Ensure recent games are unique and limit to 5
const displayedRecent = computed(() => {
    const unique = new Map(recent.value.map(game => [game.identifier, game]))
    return Array.from(unique.values()).slice(-5)
})

const searchGames = useDebounceFn(async () => {
    try {
        loading.value = true
        const response = await useMakeRequest<Game[]>(useRoute('api.game.search', { s: query.value }), 'GET')
        games.value = response || []
        if (response && response.length > 0) {
            recent.value.push(response[0])
        }
    } catch (error) {
        console.error('Error searching games:', error)
    } finally {
        loading.value = false
    }
}, 500)

watch(query, (newValue) => {
    if (newValue) {
        searchGames()
    } else {
        games.value = []
    }
})

async function loadGameData (emitValue = false) {
    try {
        const response = await useMakeRequest<Game>(useRoute('api.game.find.identifier', { identifier: selectedGame.value }), 'GET')
        gameData.value = response
        if (emitValue && response) {
            emit('update:modelValue', response.identifier)
        }
    } catch (error) {
        console.error('Error loading game data:', error)
        gameData.value = null
    }
}

function onSelect (identifier: number) {
    gameData.value = null
    selectedGame.value = identifier
    loadGameData(true)
}

function removeGame () {
    gameData.value = null
    selectedGame.value = null
    emit('update:modelValue', null)
}

watch(selectedGame, (newValue) => {
    if (newValue) {
        open.value = false
        loadGameData(true)
    }
})

onMounted(() => {
    if (selectedGame.value) {
        loadGameData()
    }
})
</script>

<template>
    <div v-if="selectedGame">
        <div
            v-if="!gameData"
            :class="white ? 'border bg-white border-primary/10 dark:bg-slate-800' : 'bg-background dark:bg-slate-800'"
            class="flex flex-col gap-2 rounded-2xl p-6 text-center bg-background">
            <Spinner class="mx-auto w-8 text-transparent" />
            <span class="text-xs text-slate-900/75">Loading game data...</span>
        </div>
        <div
            v-else
            class="flex w-full items-center">
            <GameCardHorizontal
                class="w-full"
                :class="white ? 'border bg-white border-primary/10 dark:bg-slate-800' : 'bg-background dark:bg-slate-800'"
                :actions="false"
                :new-tab="true"
                :small="small"
                :game="gameData" />
        </div>
        <div class="mt-2 flex justify-between gap-2 text-xs">
            <Button
                v-if="selectedGame"
                type="button"
                variant="danger-light"
                @click="removeGame()">
                Remove game
            </Button>
            <Button
                type="button"
                variant="white"
                @click="open = true">
                {{ selectedGame ? 'Change' : 'Select' }} game
            </Button>
        </div>
    </div>

    <div v-else>
        <button
            type="button"
            class="flex w-full gap-1 rounded-xl border bg-white px-4 py-4 text-left transition-all border-primary/10 hover:text-primary hover:bg-slate-50 focus:ring-primary/30 dark:bg-slate-800 sm:flex-row sm:items-center sm:gap-4 sm:px-4 sm:py-4 dark:hover:text-white/75"
            @click="open = true">
            <div class="flex flex-col -mt-0.5 sm:mt-0 sm:pt-0">
                <SectionTitle
                    font-size="text-lg"
                    class="flex items-center gap-2">
                    <Icon.GamePad class="flex w-8 shrink-0" />
                    Add game
                </SectionTitle>
                <SectionDescription>
                    Writing about a specific game? Add more context for readers by selecting a game.
                </SectionDescription>
            </div>
            <div class="ml-auto flex">
                <PlusIcon class="w-5" />
            </div>
        </button>
    </div>

    <TransitionRoot
        :show="open"
        as="template"
        appear
        @after-leave="query = ''">
        <Dialog
            as="div"
            class="relative isolate z-20"
            @close="open = false">
            <ModalBackdrop
                :show="true" />

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto max-w-3xl transform overflow-hidden rounded-xl bg-white shadow-lg ring-1 transition-all ring-slate-950/10 dark:bg-slate-900 dark:text-white">
                        <Combobox
                            v-slot="{ activeOption }">
                            <div class="relative">
                                <MagnifyingGlassIcon
                                    class="pointer-events-none absolute left-4 h-5 w-5 text-slate-900/30 top-3.5 dark:text-white/30"
                                    aria-hidden="true" />
                                <ComboboxInput
                                    class="h-12 w-full border-b border-zinc-200 bg-transparent pr-4 pl-11 placeholder:text-slate-400 text-slate-900 focus:outline-0 focus:ring-0 dark:text-white sm:text-sm"
                                    placeholder="Search..."
                                    @change="query = $event.target.value" />
                            </div>

                            <div
                                v-if="loading"
                                class="py-12 text-center text-sm font-semibold text-slate-900/75 dark:text-white/75">
                                <Spinner class="mx-auto mb-2 w-8 text-white dark:text-slate-900" />
                            </div>

                            <ComboboxOptions
                                v-if="(query === '' || games.length > 0) && !loading"
                                class="flex transform-gpu divide-x divide-slate-100 dark:divide-slate-800"
                                as="div"
                                static
                                hold>
                                <div
                                    :class="['max-h-128 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-128']">
                                    <h2
                                        v-if="query === '' && displayedRecent.length > 0"
                                        class="mt-2 mb-4 text-xs font-semibold text-slate-500 dark:text-white">
                                        Recent searches
                                    </h2>
                                    <h2
                                        v-if="query === '' && displayedRecent.length === 0"
                                        class="py-12 text-center text-sm font-semibold text-slate-900/75 dark:text-white/75">
                                        <Icon.GamePad class="mx-auto mb-2 w-8" />
                                        Search for games
                                    </h2>
                                    <div
                                        class="-mx-2 text-sm text-slate-700 dark:text-white">
                                        <ComboboxOption
                                            v-for="game in query === '' ? displayedRecent.slice(0, 5) : games"
                                            :key="game.identifier"
                                            v-slot="{ active }"
                                            :value="game"
                                            as="template"
                                            @click="onSelect(game.identifier)">
                                            <div
                                                :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-slate-100 text-slate-900 dark:bg-slate-700 dark:text-white']">
                                                <div
                                                    class="w-8 flex-none overflow-hidden rounded aspect-[3/4] bg-[length:25px]"
                                                    :style="`background-image:url('${patternImage}')`">
                                                    <img
                                                        v-if="game.cover"
                                                        :src="game.cover"
                                                        alt=""
                                                        class="h-full w-full flex-none object-cover object-center aspect-[3/4]">
                                                </div>
                                                <span class="ml-3 flex-auto truncate">{{ game.name }}</span>
                                                <ChevronRightIcon
                                                    v-if="active"
                                                    class="ml-4 h-5 w-5 flex-none text-slate-800"
                                                    aria-hidden="true" />
                                            </div>
                                        </ComboboxOption>
                                    </div>
                                </div>

                                <div
                                    v-if="activeOption"
                                    class="hidden w-1/2 flex-none flex-col overflow-y-auto h-128 divide-y divide-slate-100 dark:divide-slate-800 sm:flex">
                                    <div class="flex-none p-6 text-center">
                                        <div
                                            class="mx-auto w-32 overflow-hidden rounded-xl bg-slate-200 bg-center aspect-[3/4] dark:bg-slate-800 md:rounded-2xl bg-[length:50px]"
                                            :style="`background-image:url('${patternImage}')`"
                                        >
                                            <img
                                                v-if="activeOption.cover"
                                                :src="activeOption.cover"
                                                alt=""
                                                class="h-full w-full object-cover object-center">
                                        </div>
                                        <h2
                                            v-if="activeOption.name"
                                            class="mt-3 font-semibold text-slate-900 dark:text-white">
                                            {{ activeOption.name }}
                                        </h2>
                                        <p
                                            v-if="activeOption.release_date_nice"
                                            class="text-sm leading-6 text-slate-500 dark:text-white/50">
                                            {{ activeOption.release_date_nice }}
                                        </p>
                                    </div>
                                    <div class="flex flex-auto flex-col justify-between p-6">
                                        <dl class="mb-4 grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-slate-700 dark:text-white">
                                            <dt
                                                v-if="activeOption.platform_names"
                                                class="col-end-1 font-semibold text-slate-900 dark:text-white">
                                                Platforms
                                            </dt>
                                            <dd v-if="activeOption.platform_names">
                                                <div class="max-w-none prose prose-sm dark:text-white">
                                                    {{ activeOption.platform_names }}
                                                </div>
                                            </dd>
                                        </dl>

                                        <p
                                            v-if="activeOption.description"
                                            class="text-sm font-semibold text-slate-900 dark:text-white">
                                            About
                                        </p>
                                        <div
                                            v-if="activeOption.description"
                                            class="-mt-4 break-words text-sm">
                                            <p>
                                                {{ activeOption.description.slice(0, 200) }}...
                                            </p>
                                        </div>

                                        <a
                                            :href="activeOption.permalink"
                                            target="_blank"
                                            class="mb-4 text-sm text-primary hover:underline">
                                            Learn more
                                        </a>
                                        <Button
                                            type="button"
                                            @click="onSelect(activeOption.identifier)">
                                            Select game
                                        </Button>
                                    </div>
                                </div>
                            </ComboboxOptions>

                            <div
                                v-if="query !== '' && games.length === 0 && !loading"
                                class="px-6 py-14 text-center text-sm sm:px-14">
                                <h2
                                    class="mt-2 mb-2 text-center text-sm font-semibold text-slate-900/75 dark:text-white/75">
                                    <Icon.GamePad class="mx-auto mb-2 w-8" />
                                    No games found
                                </h2>

                                <p class="text-slate-500">
                                    We couldn’t find anything with that term. Please try again.
                                </p>
                            </div>
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
