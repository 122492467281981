<script setup lang="ts">
import PostList from '@/Components/Post/PostList.vue'
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
import { router } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import type { Post } from '@/Types/Models/Post'
import type { ImageUrls } from '@/Types/ImageUrls'
import { type Prop, type PropType, ref } from 'vue'
import type { PaginatedItems, PaginationMeta } from '@/Types/PaginatedItems'

defineProps({
    user: Object as Prop<User>,
    posts: [Object as PropType<PaginatedItems<Post>>, Object as PropType<Post[]>],
    media: Array as PropType<ImageUrls[]>,
    paginated: Boolean
})

const emit = defineEmits(['reload'])
const postsKey = ref(0)

function reload () {
    router.reload({
        only: ['posts'],
        onSuccess: () => {
            postsKey.value++
            emit('reload')
        }
    })
}

defineOptions({ layout: ProfileLayout })
</script>

<template>
    <div class="max-w-3xl">
        <div class="flex gap-8">
            <div class="w-full">
                <PostList
                    :key="postsKey"
                    :paginated="paginated"
                    :user="user"
                    :posts="posts"
                    @reload="reload" />
            </div>
        </div>
    </div>
</template>
