<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Modal from '@/Components/UI/Modal.vue'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import { type PropType, ref } from 'vue'
import { usePost } from '@/Composables/usePost'
import type { Game } from '@/Types/Models/Game'
import { useToast } from '@/Composables/useToast.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid'
import { NotificationType } from '@/Enums/NotificationType.ts'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

const props = defineProps({
    followUrl: String,
    unfollowUrl: String,
    game: Object as PropType<Game>,
    confirmUnfollow: {
        type: Boolean,
        default: true
    },
    showLoginModal: {
        type: Boolean,
        default: true
    },
    padding: {
        type: String
    },
    small: {
        type: Boolean,
        default: false
    },
    iconOnly: {
        type: Boolean,
        default: false
    },
    following: Boolean
})

const emit = defineEmits(['followed', 'unfollowed', 'updated'])

const isFollowing = ref(props.game.isFollowing)
const displayConfirmUnfollow = ref(false)

useEmitter.on('followersUpdated', function (e: { game: number, data: any }) {
    if (e.game === props.game.identifier) {
        isFollowing.value = e.data.followed
    }
})

function follow () {
    isFollowing.value = true
    usePost({
        url: props.game.followUrl,
        data: {
            user_id: usePageProps().auth.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
        onSuccess: response => {
            useToast(response.message, NotificationType.SUCCESS)
            emit('followed')
            emit('updated', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followed', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                game: props.game.identifier,
                data: response
            })

            useAnalyticsEvent('Follow game')
        }
    })
}

function unfollow () {
    isFollowing.value = false
    displayConfirmUnfollow.value = false
    usePost({
        url: props.game.unfollowUrl,
        method: 'DELETE',
        data: {
            user_id: usePageProps().auth.user.id
        },
        onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
        onSuccess: response => {
            useToast(response.message, NotificationType.SUCCESS)
            emit('unfollowed')
            emit('updated', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followed', {
                game: props.game.identifier,
                data: response
            })
            useEmitter.emit('followersUpdated', {
                game: props.game.identifier,
                data: response
            })
            useAnalyticsEvent('Unfollow game')
        }
    })
}

function handleClick () {
    if (isFollowing.value) {
        if (props.confirmUnfollow) {
            displayConfirmUnfollow.value = true
        } else {
            unfollow()
        }
    } else {
        follow()
    }
}
</script>

<template>
    <div>
        <div
            v-if="useIsAuthed() && usePermissions(UserPermission.FOLLOW_GAME)"
            class="w-full">
            <Button
                v-if="isFollowing"
                v-tooltip="`Unfollow game`"
                rounded
                :small="small"
                :icon-only="small"
                variant="white"
                :icon="MinusIcon"
                @click="handleClick">
                <span v-if="!iconOnly">
                    Following
                </span>
            </Button>
            <Button
                v-else
                v-tooltip="`Follow game`"
                rounded
                :small="small"
                :icon-only="small"
                :icon="PlusIcon"
                @click="handleClick">
                <span :class="small ? 'text-xs' : ''">Follow</span>
            </Button>
        </div>

        <div v-if="!useIsAuthed() && showLoginModal">
            <AuthModalTrigger
                default-form="login"
                login-title="Login to follow users">
                <template #trigger>
                    <Button
                        :padding="padding"
                        variant="primary">
                        <span class="flex">Follow this game</span>
                        <Icon.GamePad class="ml-1 w-5 text-white xs:ml-2 xs:w-4" />
                    </Button>
                </template>
            </AuthModalTrigger>
        </div>

        <Modal
            :show="displayConfirmUnfollow"
            max-width="md"
            @close="displayConfirmUnfollow = false">
            <template #title>
                Unfollow {{ game.name }}
            </template>

            <template #content>
                Are you sure you want to unfollow {{ game.name }}
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="displayConfirmUnfollow = false">
                        Cancel
                    </Button>

                    <Button
                        variant="secondary"
                        @click="unfollow()">
                        Yes, unfollow
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
