<script lang="ts" setup>
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import TagList from '@/Components/Tag/TagList.vue'
import interactImage from '~/app/icons/interact.svg'
import GameGrid from '@/Components/Game/GameGrid.vue'
import WritingCta from '@/Components/Global/WritingCta.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import GlobalBanner from '@/Components/Global/GlobalBanner.vue'
import WelcomeBanner from '@/Components/Global/WelcomeBanner.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import SocialLinksMinimal from '@/Components/Global/SocialLinksMinimal.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import { onMounted, ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { Tag } from '@/Types/Models/Tag'
import type { Game } from '@/Types/Models/Game'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { SparklesIcon } from '@heroicons/vue/24/solid'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { ArticleGridList } from '@/Types/ArticleGridList'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'

const props = defineProps<{
    hero?: Article | null;
    // followingCount: number;
    bookmarksCount: number;

    articlesCount: number | string;
    usersCount: number | string;
    viewsCount: number | string;

    // initialTrending: ArticleGridList;
    // initialFollowing: ArticleGridList;
    initialLatest: ArticleGridList;
    initialRecommended: ArticleGridList;
    initialPopularTags: Tag[] | null;
    initialPopularGames: Game[] | null;
    // initialPopularStreamingGames: Game[] | null;
    initialTrendingTopics: Tag[] | null;

    // recentTags?: Tag[] | null,
    // recentTagsArticles?: ArticleGridList;
}>()

const bookmarked = ref<ArticleGridList>(null)
const onThisDay = ref<ArticleGridList>(null)
const latest = ref<ArticleGridList>(props.initialLatest)
// const trending = ref<ArticleGridList>(props.initialTrending)
const trendingTopics = ref<Tag[]>(props.initialTrendingTopics)
// const following = ref<ArticleGridList>(props.initialFollowing)
const recommended = ref<ArticleGridList>(props.initialRecommended)
const popularTags = ref<Tag[] | []>(props.initialPopularTags)
// const popularStreamingGames = ref<Game[] | []>(props.initialPopularStreamingGames)
const popularGames = ref<Game[] | []>(props.initialPopularGames)

useEmitter.on('bookmarked', loadBookmarks)
useEmitter.on('unbookmarked', loadBookmarks)

function loadBookmarks () {
    useMakeRequest(useRoute('api.home.bookmarked'), 'POST').then(response => bookmarked.value = response)
}

function loadOnThisDay () {
    useMakeRequest(useRoute('api.home.onThisDay'), 'POST').then(response => onThisDay.value = response)
}

function loadArticles () {
    loadOnThisDay()

    if (useIsAuthed()) {
        if (recommended.value === null) {
            useMakeRequest(useRoute('api.home.recommended'), 'POST').then(response => {
                recommended.value = response
            })
        }
        loadBookmarks()
    }

    if (latest.value === null) {
        useMakeRequest(useRoute('api.home.latest'), 'POST').then(response => latest.value = response)
    }

    // if (trending.value === null) {
    //     useMakeRequest(useRoute('api.home.trending'), 'POST').then(response => trending.value = response)
    // }

    if (popularTags.value === null) {
        useMakeRequest(useRoute('api.home.popularTags'), 'POST').then(response => popularTags.value = response)
    }

    if (trendingTopics.value === null) {
        useMakeRequest(useRoute('api.home.trendingTopics'), 'POST').then(response => trendingTopics.value = response)
    }
}

function loadGames () {
    // if (popularStreamingGames.value === null) {
    //     useMakeRequest(useRoute('api.home.popularStreamingGames'), 'POST').then(response => popularStreamingGames.value = response)
    // }

    if (popularGames.value === null) {
        useMakeRequest(useRoute('api.home.popularGames'), 'POST').then(response => popularGames.value = response)
    }
}

onMounted(() => {
    loadArticles()
    loadGames()
})
</script>

<template>
    <div>
        <GlobalBanner
            title="Account created"
            content="Visit your dashboard to get started" />

        <WelcomeBanner
            :articles-count="articlesCount ?? 0"
            :users-count="usersCount ?? 0"
            :views-count="viewsCount ?? 0"
            class="m-4 mb-8 overflow-hidden rounded-2xl" />

        <div class="container mx-auto mt-8 flex w-full flex-col items-stretch gap-8 pb-12 md:flex-row">
            <div class="w-full md:w-8/12 xl:w-9/12">
                <ArticleGrid
                    :articles="latest"
                    :contain="false"
                    :skeleton-count="3"
                    :button-limit="3"
                    :allow-ad="true"
                    :include-writing-cta="true"
                    article-width="sm:w-1/2 lg:w-1/3"
                    :url="useRoute('articles.index')"
                    title="Latest gaming articles">
                    <template #empty>
                        <EmptyState
                            title="No articles"
                            description="Write your own and grow your very own community">
                            <template #button>
                                <div class="flex">
                                    <ArticleCreateButton />
                                </div>
                            </template>
                        </EmptyState>
                    </template>
                </ArticleGrid>

                <div class="mt-8 mb-12 w-full">
                    <WritingCta />
                </div>

                <ArticleGrid
                    v-if="useIsAuthed() && recommended"
                    :articles="recommended"
                    :contain="false"
                    :skeleton-count="3"
                    article-width="sm:w-1/2 lg:w-1/3"
                    title="For you">
                    <template #empty>
                        <EmptyState
                            :icon="interactImage"
                            :icon-width="96"
                            :icon-height="29"
                            title="No recommended articles"
                            description="Like, bookmark, and interact with articles to get recommendations based on your interests.">
                            <template #button>
                                <div class="flex justify-center">
                                    <Button
                                        :href="useRoute('articles.index')"
                                        variant="primary">
                                        <span>Explore articles</span>
                                    </Button>
                                </div>
                            </template>
                        </EmptyState>
                    </template>
                </ArticleGrid>

                <div v-if="!useIsAuthed()">
                    <SectionTitle font-size="text-xl md:text-2xl">
                        <div class="flex items-center">
                            For you
                        </div>
                    </SectionTitle>
                    <EmptyState
                        :icon="interactImage"
                        title="Recommended articles"
                        description="Sign up for free to interact with articles, games, tags and discover more with smart recommendations.">
                        <template #button>
                            <div class="flex">
                                <AuthModalTrigger
                                    default-form="register">
                                    <template #trigger>
                                        <Button
                                            variant="primary">
                                            <span>Sign up here</span>
                                        </Button>
                                    </template>
                                </AuthModalTrigger>
                            </div>
                        </template>
                    </EmptyState>
                </div>

                <div class="mt-12">
                    <div class="flex items-center justify-between">
                        <SectionTitle>
                            Popular games
                        </SectionTitle>
                        <Button
                            :href="useRoute('games.index')"
                            variant="text"
                            :icon="ArrowRightIcon">
                            See more
                        </Button>
                    </div>
                    <GameGrid
                        class="mt-2"
                        :skeleton-count="4"
                        :games="popularGames ? popularGames.slice(0, 4) : popularGames" />
                </div>
            </div>
            <div class="relative flex w-full flex-col gap-8 pt-10 pb-8 md:w-4/12">
                <Card v-if="popularTags === null || popularTags.length > 0">
                    <TagList
                        :center="false"
                        :small="true"
                        :tags="popularTags"
                        :title="`Popular tags on ${usePageProps().app.name}`" />
                </Card>

                <Card>
                    <TagList
                        :center="false"
                        :small="true"
                        title="Global trends"
                        description="These topics are sourced from multiple sources and summarised with AI."
                        :icon="SparklesIcon"
                        :tags="trendingTopics" />
                </Card>

                <Card
                    v-if="bookmarksCount">
                    <ArticleGrid
                        :articles="bookmarked"
                        :contain="false"
                        :horizontal="true"
                        title="Bookmarked articles">
                        <template #empty>
                            <SectionDescription class="mt-4 text-center">
                                You don't have any bookmarked articles
                            </SectionDescription>
                            <div class="mt-2 mb-4 flex">
                                <Button
                                    :href="useRoute('articles.index')"
                                    variant="primary"
                                    class="mx-auto">
                                    Explore articles
                                </Button>
                            </div>
                        </template>
                    </ArticleGrid>
                    <div
                        v-if="bookmarked && bookmarked.length > 4"
                        class="mt-4 flex border-t pt-4 border-primary/10">
                        <Link
                            :href="useRoute('articles.bookmarked')"
                            class="hidden text-sm font-bold transition-all hover:text-primary md:flex"
                            variant="primary">
                            View all
                            <ArrowRightIcon class="ml-2 w-5" />
                        </Link>
                    </div>
                </Card>

                <Card
                    v-if="onThisDay && onThisDay.length">
                    <ArticleGrid
                        :articles="onThisDay"
                        :contain="false"
                        :horizontal="true"
                        title="On this day"
                        description="Articles published on this date" />
                    <div class="mt-4 flex border-t pt-4 border-primary/10">
                        <Button
                            :href="useRoute('articles.on-this-day')"
                            :icon="ArrowRightIcon"
                            variant="text">
                            View all
                        </Button>
                    </div>
                </Card>

                <div class="top-20 flex w-full flex-col gap-8 md:sticky">
                    <AdContainer
                        ad-slot="homeSidebar"
                        padding="mx-2" />
                    <Card class="w-full">
                        <SectionTitle>Follow us</SectionTitle>
                        <SectionDescription>Connect with us and keep up to date on your favorite social media platforms.</SectionDescription>
                        <div class="my-5 md:mt-5 md:mb-2">
                            <SocialLinksMinimal />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>
