<script setup lang="ts">
import GameCard from '@/Components/Game/GameCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import { computed, type PropType, useSlots } from 'vue'
import type { PaginationMeta } from '@/Types/PaginatedItems'

const props = defineProps({
    games: [Object, Array] as PropType<any>,
    small: {
        type: Boolean,
        default: false
    },
    pagination: [Object, null] as PropType<PaginationMeta>,
    skeletonCount: {
        type: Number,
        default: 6
    },
    allowAd: {
        type: Boolean,
        default: true
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    gameWidth: {
        type: String,
        default: 'w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4'
    }
})

const allGames = computed(() => {
    return Array.isArray(props.games) ? props.games : props.games.data
})

const containerClasses = computed(() => {
    return 'md:no-container'
})

const gameClasses = computed(() => {
    return props.gameWidth
})

const slots = useSlots()
</script>

<template>
    <div>
        <GameSkeletonGrid
            v-if="!games && skeletonCount > 0"
            :game-width="gameWidth"
            :class="containerClasses"
            :number="skeletonCount" />
        <div v-else>
            <div
                v-if="allGames.length"
                :key="pagination ? pagination.current_page : null"
                :class="[containerClasses]">
                <div class="-mx-1 mt-0 flex flex-wrap items-stretch">
                    <template
                        v-for="(game, index) in allGames"
                        :key="game.identifier">
                        <div
                            v-if="allowAd && (index === 5 || index === 10)"
                            class="mx-2 my-4 w-full">
                            <AdContainer
                                ad-slot="inGamesGridHorizontal" />
                        </div>
                        <div
                            v-if="!horizontal"
                            :class="[gameClasses, small ? '' : 'p-1 md:p-2']">
                            <GameCard
                                :small="small"
                                :game="game"
                            />
                        </div>
                        <div
                            v-else
                            class="w-full p-1">
                            <GameCardHorizontal
                                :game="game"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <slot
                v-if="allGames.length === 0"
                name="empty" />

            <Pagination
                v-if="games.meta || games.links"
                :show-end-links="false"
                :show-count="true"
                :data="games" />
        </div>
    </div>
</template>
