<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { useToast } from '@/Composables/useToast.ts'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { BookmarkIcon } from '@heroicons/vue/24/outline'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePermissions } from '@/Composables/usePermissions'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'
import { getCurrentInstance, onMounted, type PropType, ref } from 'vue'
import { BookmarkIcon as BookmarkSolidIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    article: Object as PropType<Article>,
    bookmarked: Boolean,
    small: {
        type: Boolean,
        default: false
    },
    showLoginModal: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['bookmarked', 'unbookmarked'])

const animation = ref(false)
const saved = ref(props.bookmarked)
const inverted = ref(props.bookmarked)

const instance = getCurrentInstance()
const uid = ref(instance.uid)

function save () {
    animation.value = true
    saved.value = true
    useMakeRequest(useRoute('api.article.bookmark.add', props.article), 'POST', {})
        .then(results => {
            if (results.success === true) {
                emit('bookmarked')
                useToast('Saved to bookmarks')
                useAnalyticsEvent('Bookmark article')

                useEmitter.emit('bookmarked', {
                    article: props.article.uuid,
                    component: uid.value
                })
            }
        })

    // router.post(useRoute('articles.bookmark.add', props.article), {}, {
    //     preserveScroll: true,
    //     preserveState: true,
    //     onSuccess: () => useEmitter.emit('bookmarked')
    // })

    setTimeout(() => {
        animation.value = false
        setTimeout(() => {
            inverted.value = true
        }, 100)
    }, 750)
}

function remove () {
    animation.value = true
    saved.value = false

    useMakeRequest(useRoute('api.article.bookmark.remove', props.article), 'DELETE', {})
        .then(results => {
            if (results.success === true) {
                emit('unbookmarked')
                useToast('Removed from bookmarks')
                useAnalyticsEvent('Unbookmark article')

                useEmitter.emit('unbookmarked', {
                    article: props.article.uuid,
                    component: uid.value
                })
            }
        })

    setTimeout(() => {
        animation.value = false
        setTimeout(() => {
            inverted.value = false
        }, 100)
    }, 750)
}

function toggle () {
    if (useIsAuthed() && usePermissions(UserPermission.BOOKMARK_ARTICLES)) {
        saved.value === true ? remove() : save()
    } else if (props.showLoginModal && !useIsAuthed()) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to bookmark articles',
            defaultForm: 'login'
        })
    }
}

onMounted(() => {
    useEmitter.on('bookmarked', function (event: { article: Article['uuid'], component: number}) {
        if (event.article === props.article.uuid && event.component !== uid.value) {
            saved.value = !saved.value
        }
    })

    useEmitter.on('unbookmarked', function (event: { article: Article['uuid'], component: number}) {
        if (event.article === props.article.uuid && event.component !== uid.value) {
            saved.value = !saved.value
        }
    })
})
</script>

<template>
    <div>
        <Button
            v-tooltip="saved ? 'Unbookmark article' : 'Bookmark article'"
            variant="white"
            rounded
            icon-only
            :small="small"
            class="text-primary"
            :icon="saved ? BookmarkSolidIcon : BookmarkIcon"
            @click="toggle" />
    </div>
</template>
