<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, ref, useSlots } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline/index.js'

const props = defineProps({
    modelValue: String,
    iconClasses: String,
    label: String,
    autocomplete: String,
    max: [String, null],
    step: [String, null],
    min: [String, null],
    inputClasses: [String, null],
    inputId: [String, null],
    error: {
        type: [String, null],
        default: null
    },
    hasAutofocus: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    inputType: {
        type: String,
        default: 'text'
    },
    clearable: {
        type: Boolean,
        default: false
    },
    inline: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const slots = useSlots()

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'cleared'])

const input = ref(null)
const id = ref(props.inputId)

function focus () {
    input.value.focus()
}

defineExpose({
    focus
})

const displayType = ref(props.inputType ?? 'text')

function swapType () {
    displayType.value = displayType.value === 'password' ? (props.type ?? 'text') : 'password'
}

function clearValue () {
    emit('update:modelValue', '')
    emit('cleared')
}

onMounted(() => {
    if (!props.inputId) {
        id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
    }
    if (props.hasAutofocus) {
        input.value.focus()
    }
})
</script>

<template>
    <div>
        <div class="relative group text-secondary/30 group-focus:text-primary focus-within:text-primary">
            <InputLabel
                v-if="label"
                :for-input="id">
                {{ label }}
            </InputLabel>
            <div class="relative">
                <div
                    v-if="slots.icon"
                    class="absolute bottom-0 left-1 flex aspect-square h-full items-center justify-center">
                    <div
                        :class="iconClasses">
                        <slot name="icon" />
                    </div>
                </div>
                <div
                    v-if="clearable && modelValue"
                    class="absolute right-1 bottom-0 flex aspect-square h-8 items-center justify-center mb-[3px]">
                    <button
                        v-tooltip="'Clear'"
                        class="text-slate-900/75 hover:text-primary focus:text-primary focus:outline-none dark:text-white/75"
                        type="button"
                        @click="clearValue()">
                        <span class="sr-only">Clear input</span>
                        <XMarkIcon class="w-4" />
                    </button>
                </div>

                <button
                    v-if="inputType === 'password'"
                    type="button"
                    class="absolute text-slate-900/50 bottom-2.5 right-2.5 hover:text-slate-900 focus:outline-none dark:text-white dark:hover:text-white/50"
                    @click="swapType">
                    <component
                        :is="displayType === 'password' ? EyeIcon : EyeSlashIcon"
                        class="w-5" />
                </button>
                <input
                    :id="id"
                    ref="input"
                    :autocomplete="autocomplete"
                    :class="[
                        inputClasses,
                        error ? 'border-red-500' : 'border-zinc-200',
                        slots.icon ? 'pl-10' : '', clearable ? 'pr-10' : '',
                        inline ? 'border-0 focus:outline-transparent' : 'border shadow-sm rounded-lg focus:ring-0 focus:outline-primary'
                    ]"
                    :disabled="disabled"
                    :max="max"
                    :min="min"
                    :placeholder="placeholder"
                    :step="step"
                    :type="displayType ?? inputType"
                    :value="modelValue"
                    class="h-10 w-full px-3 text-sm font-normal text-slate-900 focus:border-primary dark:bg-slate-800 dark:text-white dark:disabled:text-white/50"
                    @blur="emit('blur')"
                    @focus="emit('focus')"
                    @input="emit('update:modelValue', $event.target.value)">
            </div>
        </div>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
