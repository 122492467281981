<script setup>
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost'
import { useToast } from '@/Composables/useToast.ts'
import { HeartIcon } from '@heroicons/vue/24/outline'
import { usePlural } from '@/Composables/usePlural.js'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { HeartIcon as FullHeartIcon } from '@heroicons/vue/24/solid'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.js'

const props = defineProps({
    url: String,
    count: Number,
    liked: Boolean,
    isMe: Boolean,
    showText: {
        type: Boolean,
        default: false
    },
    likeTooltip: {
        type: [String, null],
        default: null
    },
    padding: {
        type: [String, null],
        default: 'px-2 py-1.5'
    },
    unlikeTooltip: {
        type: [String, null],
        default: null
    },
    can: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    light: {
        type: Boolean,
        default: false
    },
    showLoginModal: {
        type: Boolean,
        default: true
    },
    showCount: {
        type: Boolean,
        default: true
    }
})

const displayCount = ref(props.count)
const displayLiked = ref(props.liked)
const displayPulse = ref(false)

useEmitter.on('updateLikesCount', ({ url, count, liked }) => {
    if (url === props.url) {
        displayCount.value = count
        displayLiked.value = liked
    }
})

function handleClick () {
    if (props.showLoginModal && !useIsAuthed()) {
        useEmitter.emit('openAuthModal', {
            loginTitle: 'Login to like things',
            defaultForm: 'login'
        })
    }

    if (!props.isMe && props.can) {
        displayLiked.value = !displayLiked.value

        if (!displayLiked.value) {
            displayCount.value = displayCount.value - 1
        } else {
            displayCount.value = displayCount.value + 1
            displayPulse.value = true
        }

        usePost({
            url: props.url,
            onUnauthorized: () => useToast('Unauthorized action', 'fail'),
            onSuccess: response => {
                displayCount.value = response.count
                displayLiked.value = response.liked

                useEmitter.emit('updateLikesCount', {
                    url: props.url,
                    count: response.count,
                    liked: response.liked
                })

                useAnalyticsEvent(response.liked ? 'Like item' : 'Unlike item')
            }
        })
    }
}
</script>
<template>
    <div class="flex items-center">
        <Button
            v-tooltip="displayLiked ? unlikeTooltip : likeTooltip"
            variant="white"
            :disabled="isMe"
            rounded
            icon-first
            :icon-only="!showCount"
            :small="small"
            @click="handleClick">
            <template #icon>
                <span v-if="!displayLiked && !isMe">
                    <HeartIcon
                        :class="small ? 'w-4' : 'w-5'" />
                </span>
                <span
                    v-else>
                    <FullHeartIcon
                        v-if="displayPulse && !isMe"
                        :class="small ? 'w-4' : 'w-5'"
                        class="absolute left-0 text-red-500 top-0.5 animate-ping-once" />
                    <FullHeartIcon
                        :class="[isMe ? 'opacity-50 text-gray-500' : 'text-red-500', small ? 'w-4' : 'w-5']" />
                </span>
            </template>
            <template #default>
                <span
                    v-if="showCount"
                    class="flex items-center justify-center text-center min-w-4">
                    {{ displayCount }} {{ showText ? usePlural('Like', displayCount) : '' }}
                </span>
            </template>
        </Button>
    </div>
</template>
